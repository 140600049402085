<template>
  <div class="kctxPage">
    <img src="../assets/kctx/banner.png" class="banner" />
    <div class="bannerSj"></div>
    <!-- 教研团队 -->
    <div class="jytd">
      <div class="jytd1">教研团队</div>
      <div class="jytd2"></div>
    </div>
    <div class="kctxPage_mySwiper_Parent">
      <div class="swiper kctxPage_mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <img class="person" src="../assets/kctx/person1.png" />
            <img class="personName" src="../assets/kctx/personName1.png" height="18px" />
            <div class="blueBlock"></div>
            <div class="personDesc">
              哈尔滨工业大学自动化测试与控制系，硕士；
              2007年—2016年曾在军工研究所工作，主要从事无人飞行器电气总体设计和微小型涡喷发动机设计，曾为某武器系统副总工程师，国防科技进步二等奖、军工集团科技进步三等奖多项。
            </div>
          </div>
          <div class="swiper-slide">
            <img class="person" src="../assets/kctx/person2.png" />
            <img class="personName" src="../assets/kctx/personName2.png" height="18px" />
            <div class="blueBlock"></div>
            <div class="personDesc">
              原哈尔滨工业大学航天学院教授，从事教育工作20多年，曾获国家部级一等奖、三等奖多次，核心期刊发表论文多项。全国无人机专业大赛特约专家，青少年航模飞行培训资深专家，航模运动狂热爱好者，从事无人机产业化及无人机教育工作多年。
            </div>
          </div>
          <div class="swiper-slide">
            <img class="person" src="../assets/kctx/person3.png" />
            <img class="personName" src="../assets/kctx/personName3.png" />
            <div class="blueBlock"></div>
            <div class="personDesc">
              大学期间获得全国航空模型公开赛的二等奖，省挑战杯二等奖等；毕业后从事航空科普教育，先后培养国赛级飞手十几名，曾担任宜春市航空无线电模型运动协会副主席，参与组织了宜春市青少年航空航天模型教育竞赛暨第一届“飞向北京、飞向太空”全国青少年航空模型教育竞赛活动选拔赛。教授了数十所中小学，近两百学生航空原理和竞赛知识。所教授的学校包揽团体奖一二三名。
            </div>
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
      <div class="swiper-button-next swiperRight"></div>
      <div class="swiper-button-prev swiperLeft"></div>
    </div>
    <!-- 课程体系 -->
    <div class="jytd kctx">
      <div class="jytd1">课程体系</div>
      <div class="jytd2"></div>
    </div>
    <div class="cards">
      <div class="card">
        <img class="img1" src="../assets/kctx/card1.png" />
        <img class="img2" src="../assets/kctx/cardName1.png" />
        <div class="blueBlock"></div>
        <div class="desc">基于公司军工技术优势</div>
      </div>
      <div class="card blue">
        <img class="img1" src="../assets/kctx/card2.png" />
        <img class="img2" src="../assets/kctx/cardName2.png" />
        <div class="blueBlock"></div>
        <div class="desc">吸收国际先进教学体系</div>
      </div>
      <div class="card">
        <img class="img1" src="../assets/kctx/card3.png" />
        <img class="img2" src="../assets/kctx/cardName3.png" />
        <div class="blueBlock"></div>
        <div class="desc">搭建多维课程体系</div>
      </div>
    </div>
    <!-- 课程导学图 -->
    <div class="jytd kctx">
      <div class="jytd1">课程导学图</div>
      <div class="jytd2"></div>
    </div>
    <div class="kcdxt2"></div>
    <!-- 轻双师教学 -->
    <div class="jytd kctx">
      <div class="jytd1">轻双师教学</div>
      <div class="jytd2"></div>
    </div>
    <div class="videoParent">
      <div class="videoBg1"></div>
      <div class="videoBg2"></div>
      <!-- <div class="video"></div> -->
      <video
        class="video"
        controls
        controlslist="noplaybackrate nodownload"
        disablePictureInPicture
      >
        <source src="../assets/video.mp4" />
      </video>
    </div>
    <!-- 教学体系 -->
    <div class="jytd kctx">
      <div class="jytd1">教学体系</div>
      <div class="jytd2"></div>
    </div>
    <!-- 教材展示 -->
    <div class="jczs">
      <div class="jczsTitle">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">教材展示</div>
      </div>
      <div class="jczsContent">
        <img src="../assets/kctx/jczs1.png" />
        <img src="../assets/kctx/jczs2.png" />
        <img src="../assets/kctx/jczs3.png" />
      </div>
    </div>
    <div class="jxff"></div>
    <!-- 培养目标 -->
    <div class="jczs">
      <div class="jczsTitle">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">培养目标</div>
      </div>
    </div>
    <div class="pymbContent">
      <div class="pymbLeft">
        <img class="cardImg" src="../assets/kctx/cardImg1.png" />
        <div class="orangeBlock"></div>
        <div class="age">6-12岁</div>
        <div class="ageDesc">
          以航模和编程为载体 引导学生认识航空<br />
          航天相关知识 培养学生解决问题的思维
        </div>
        <img class="cardImg" src="../assets/kctx/cardImg3.png" />
        <div class="orangeBlock"></div>
        <div class="age">实践</div>
        <div class="ageDesc">
          超过12000所学校教学案例<br />
          航模 无人机领域专家 受儿童心理学家评估认可
        </div>
        <img class="cardImg" src="../assets/kctx/cardImg5.png" />
      </div>
      <div class="pymbRight">
        <div class="orangeBlock"></div>
        <div class="age">3-6岁</div>
        <div class="ageDesc">
          借助游戏的方式了解人类飞天历史<br />
          借助实验的方式感知飞行相关原理
        </div>
        <img class="cardImg" src="../assets/kctx/cardImg2.png" />
        <div class="orangeBlock"></div>
        <div class="age">研发</div>
        <div class="ageDesc">
          行业标杆研发团队 基于经典迭代<br />
          轻双师教学课堂 标准规划课程设计
        </div>
        <img class="cardImg" src="../assets/kctx/cardImg4.png" />
        <div class="orangeBlock"></div>
        <div class="age">体系</div>
        <div class="ageDesc">
          打造3~15岁航模 无人机学习系统<br />
          运用15000+视频 动画 游戏<br />
          独家创建多元教学体系与课程体系
        </div>
      </div>
    </div>

    <!-- 培养价值观 -->
    <div class="jczs">
      <div class="jczsTitle">
        <div class="blueBlock1"></div>
        <div class="blueBlock2">培养价值观(To Nurture 21 Values )</div>
      </div>
    </div>
    <div class="pyjzg1"></div>
    <div class="pyjzg2">
      勇敢 Courage、友好Friendliness、细心Prudence、智慧Wisdom、善良Kindness、思考Thinking、<br />
      礼貌Politeness、责任Responsibility、友善Fraternization、秩序Order、安全Safe、守时Punctuality
      <br />
      、积极Optimism、公平Equality、健康Health、公正Justice、尊重Respect、正直Integrity、<br />
      宽容Tolerance、创新 Innovation、合作Cooperation
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
import 'swiper/css/swiper.min.css'; // 注意这里的引入
export default {
  name: 'kctx',
  components: {},
  mounted(){
    new Swiper(".kctxPage_mySwiper", {
      slidesPerView: 3,
      spaceBetween: 30,
      loop: true,
      //   pagination: {
      //     el: ".swiper-pagination",
      //     clickable: true
      //   }
      navigation: {
        nextEl: ".swiperLeft",
        prevEl: ".swiperRight"
      }
    });
  }
};
</script>
<style lang="less">
.kctxPage {
  .banner {
    width: 100%;
    display: block;
  }
  .bannerSj {
    width: 0;
    height: 0;
    border-left: 85/19.2vw solid transparent;
    border-right: 85/19.2vw solid transparent;
    border-top: 85/19.2vw solid #0172bb;
    margin: 0 auto;
  }
  .jytd {
    width: 413/19.2vw;
    height: 114/19.2vw;
    position: relative;
    margin: 65/19.2vw auto 94/19.2vw;
    &.kctx {
      margin: 93/19.2vw auto 122/19.2vw;
    }
    > div {
      width: 400/19.2vw;
      height: 100/19.2vw;
    }
    .jytd1 {
      background: #ff9327;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(255, 255, 255);
      line-height: 40/19.2vw;
      font-style: italic;
      font-weight: bold;
    }
    .jytd2 {
      background: #0e7ab9;
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  .kctxPage_mySwiper_Parent {
    width: 1613/19.2vw;
    height: 752/19.2vw;
    position: relative;
    padding: 0 60/19.2vw;
    margin: 0 auto;
    .swiperLeft {
      position: absolute;
      width: 66/19.2vw;
      height: 112/19.2vw;
      background: url('../assets/kctx/swiperLeft.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translate(0, -50%);
      left: 0;
      cursor: pointer;
    }
    .swiperRight {
      position: absolute;
      width: 66/19.2vw;
      height: 112/19.2vw;
      background: url('../assets/kctx/swiperRight.png');
      background-size: 100% 100%;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
      cursor: pointer;
    }
  }
  .kctxPage_mySwiper {
    width: 1312/19.2vw;
    height: 752/19.2vw;
    overflow: hidden;
    margin: 0 auto;
  }

  .swiper-slide {
    position: relative;
    top: 58/19.2vw;
    width: 419/19.2vw;
    height: 694/19.2vw;
    background: #fff3ec;
    padding: 33/19.2vw 33/19.2vw 22/19.2vw;
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    .person {
      width: 336/19.2vw;
      height: 302/19.2vw;
      margin-bottom: 32/19.2vw;
    }
    .personName {
      margin-bottom: 12/19.2vw;
      height: 18/19.2vw;
    }
    .personDesc {
      font-size: 18.759/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(38, 38, 38);
      line-height: 1.2;
      text-indent: 2rem;
      margin-top: 17/19.2vw;
      text-align: left;
    }
  }
  .swiper-slide-next {
    position: relative;
    top: 0;
    background: #e8f1fe;
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    display: none;
  }
  .swiper-slide img {
    // display: block;
    // width: 100%;
    // height: 100%;
    // object-fit: cover;
  }
  .blueBlock {
    width: 49/19.2vw;
    height: 6/19.2vw;
    background-color: #0b7bce;
  }
  .cards {
    width: 1308/19.2vw;
    display: flex;
    justify-content: space-between;
    margin: 173/19.2vw auto 0;
  }
  .card {
    width: 419/19.2vw;
    height: 694/19.2vw;
    background: #fff3ec;
    padding: 39/19.2vw 39/19.2vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.blue {
      background: #e8f2fa;
      position: relative;
      top: -65/19.2vw;
      .img1 {
        box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      }
    }
    .img1 {
      width: 337/19.2vw;
      height: 481/19.2vw;
      margin-bottom: 36/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(206, 228, 246, 0.85);
    }
    .img2 {
      height: 20/19.2vw;
      margin-bottom: 18/19.2vw;
    }
    .desc {
      margin-top: 18/19.2vw;
      font-size: 20/19.2vw;
      font-family: 'Microsoft YaHei';
      color: rgb(38, 38, 38);
      line-height: 1.2;
    }
  }
  .kcdxt2 {
    margin: 0 auto;
    width: 1283/19.2vw;
    height: 855/19.2vw;
    background: url('../assets/kctx/kcdxt2.png');
    background-size: 100% 100%;
  }
  .videoParent {
    width: 100%;
    height: 723/19.2vw;
    position: relative;
    .videoBg1 {
      width: 100%;
      height: 621/19.2vw;
      background: url('../assets/kctx/video1.png');
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 102/19.2vw;
    }
    .videoBg2 {
      width: 100%;
      height: 199/19.2vw;
      background: url('../assets/kctx/video2.png');
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 363/19.2vw;
    }
    .video {
      width: 1170/19.2vw;
      height: 600/19.2vw;
      // background: url('../assets/kctx/video3.png');
      // background-size: 100% 100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .blueBlock1 {
    background: #0a7bd4;
    width: 12/19.2vw;
    height: 90/19.2vw;
    margin-right: 34/19.2vw;
  }
  .blueBlock2 {
    font-size: 40/19.2vw;
    font-family: 'Microsoft YaHei';
    color: rgb(20, 118, 190);
    line-height: 40/19.2vw;
    font-style: italic;
    font-weight: bold;
  }

  .jczs {
    width: 1275/19.2vw;
    margin: 0 auto;
    .jczsTitle {
      display: flex;
      align-items: center;
      margin-bottom: 60/19.2vw;
    }
    .jczsContent {
      width: 1185/19.2vw;
      height: 230/19.2vw;
      margin-left: 32/19.2vw;
      margin-bottom: 20/19.2vw;
      background: #fce3cb;
      box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      display: flex;
      justify-content: center;
      img {
        background-color: #c8cacb;
        width: 380/19.2vw;
        height: 230/19.2vw;
        margin: 0 10/19.2vw;
      }
    }
  }
  .jxff {
    margin: 0 auto;
    width: 1517/19.2vw;
    height: 954/19.2vw;
    background: url('../assets/kctx/jxff.png');
    background-size: 100% 100%;
  }
  .pyjzg1 {
    margin: 0 auto;
    width: 1286/19.2vw;
    height: 723/19.2vw;
    background: url('../assets/kctx/pyjzg1.png');
    background-size: 100% 100%;
  }
  .pyjzg2 {
    margin: 74/19.2vw auto 90/19.2vw;
    width: 1090/19.2vw;
    font-size: 24/19.2vw;
    font-family: 'Microsoft YaHei';
    color: rgb(95, 95, 95);
    line-height: 1.2;
    text-align: center;
    font-style: italic;
  }
  .pymbContent {
    display: flex;
    justify-content: center;
    margin: 0 auto 110/19.2vw;
    .orangeBlock {
      background-color: #ff932b;
      width: 56/19.2vw;
      height: 7/19.2vw;
      margin: 25/19.2vw 0 20/19.2vw;
    }
    .cardImg {
      width: 568/19.2vw;
      height: 378/19.2vw;
      box-shadow: 0px 0px 10px 10px rgba(255, 229, 207, 0.85);
      margin: 10/19.2vw;
    }
    .age {
      font-size: 24/19.2vw;
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      color: rgb(26, 119, 189);
      line-height: 1.2;
    }
    .ageDesc {
      font-size: 18/19.2vw;
      font-family: 'Microsoft YaHei';
      font-weight: bold;
      color: rgb(95, 95, 95);
      line-height: 1.2;
      margin: 20/19.2vw 0 20/19.2vw;
      font-style: italic;
    }
    .pymbLeft {
      width: 588/19.2vw;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-right: 40/19.2vw;
      .ageDesc {
        text-align: right;
      }
    }
    .pymbRight {
      width: 588/19.2vw;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 100/19.2vw;
      text-align: left;
    }
  }
}
</style>
